<template>
  <div class="px-2 py-5">
    <h1 class="title">HURLEY</h1>
    <div class="block">
      <span class="question">
        1. Tactos sinosos
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right"></div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.HUR_P1"
            :native-value="answers.HUR_P1.HUR_P1_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            No
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.HUR_P1"
            :native-value="answers.HUR_P1.HUR_P1_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.HUR_P1"
            :native-value="answers.HUR_P1.HUR_P1_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            No Aplica
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span class="question">
        2. Areas corporales
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right"></div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.HUR_P2"
            :native-value="answers.HUR_P2.HUR_P2_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            {{ question2Ans1 }}
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.HUR_P2"
            :native-value="answers.HUR_P2.HUR_P2_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            > 2
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.HUR_P2"
            :native-value="answers.HUR_P2.HUR_P2_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            No aplica
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span class="question">
        3. Lesiones
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right"></div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.HUR_P3"
            :native-value="answers.HUR_P3.HUR_P3_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Fijas
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.HUR_P3"
            :native-value="answers.HUR_P3.HUR_P3_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Migratorias
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.HUR_P3"
            :native-value="answers.HUR_P3.HUR_P3_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            No aplica
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span class="question">4. {{ question4Text }} </span>
      <br />
      <span class="ml-4">y tractos sinosos inflamados</span>

      <div class="columns">
        <div class="column has-text-right"></div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.HUR_P4"
            :native-value="answers.HUR_P4.HUR_P4_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            No
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.HUR_P4"
            :native-value="answers.HUR_P4.HUR_P4_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.HUR_P4"
            :native-value="answers.HUR_P4.HUR_P4_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            No aplica
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span class="question">
        5. Inflamación
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right"></div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.HUR_P5"
            :native-value="answers.HUR_P5.HUR_P5_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            No
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.HUR_P5"
            :native-value="answers.HUR_P5.HUR_P5_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.HUR_P5"
            :native-value="answers.HUR_P5.HUR_P5_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            No aplica
          </b-radio>
        </div>
      </div>
    </div>

    <div class="block has-text-centered title question">
      Resultado : {{ score }}
      <span v-if="isIncompleted">(Incompleto)</span>
    </div>

    <div class="buttons is-centered" v-if="!queryMode">
      <b-button class="clear-button" icon-right="block-helper" @click="clear"
        >Limpiar</b-button
      >
      <b-button class="save-button" icon-right="check-bold" @click="validate"
        >Guardar</b-button
      >
    </div>
  </div>
</template>

<script>
import { HUR as questions } from "../../../data/questions";
import { HUR as answers } from "../../../data/answers";

export default {
  data() {
    return {
      questions: questions,
      answers: answers,
      answerOne: null,
      answerTwo: null,
      answerThree: null,
      answerFour: null,
      answerFive: null,
      question2Ans1: "<=2",
      question4Text: ">= 1% BSA con fístulas intercomunicadas",
      restoreData: {},
      queryMode: false,
    };
  },
  async created() {
    if (!this.patient && !this.$route.params.surveyId) {
      this.$router.push({ name: "HurleyList" }).then(() => {
        this.showToast("No has seleccionado un paciente.", "is-warning", 3000);
      });
    }

    if (this.$route.params.surveyId) {
      this.queryMode = true;
      let data = await this.getSurveyData();
      this.setEvaluationAnswers(data);
    }

    if (this.$route.params.temporalData) {
      this.answerOne = this.$route.params.temporalData.answerOne;
      this.answerTwo = this.$route.params.temporalData.answerTwo;
      this.answerThree = this.$route.params.temporalData.answerThree;
      this.answerFour = this.$route.params.temporalData.answerFour;
      this.answerFive = this.$route.params.temporalData.answerFive;
    }

    if (!this.$route.params.temporalData && !this.$route.params.surveyId) {
      this.initialAnswer();
    }

    this.$global.$on("restore", () => {
      this.restoreForm();
    });
  },
  computed: {
    score() {
      let text = "Nulo";

      if (this.answerOne == false && this.answerTwo == false) {
        text = "Hurley I-A (leve)";
      }

      if (
        this.answerOne == false &&
        this.answerTwo == true &&
        this.answerThree == false
      ) {
        text = "Hurley I-B (Moderado)";
      }

      if (
        this.answerOne == false &&
        this.answerTwo == true &&
        this.answerThree == true
      ) {
        text = "Hurley I-C (Severo)";
      }

      if (
        this.answerOne == true &&
        this.answerFour == false &&
        this.answerFive == false
      ) {
        text = "Hurley II-A (Leve)";
      }

      if (
        this.answerOne == true &&
        this.answerTwo == false &&
        this.answerFour == false &&
        this.answerFive == true
      ) {
        text = "Hurley II-B (Moderado)";
      }

      if (
        this.answerOne == true &&
        this.answerTwo == true &&
        this.answerFour == false &&
        this.answerFive == true
      ) {
        text = "Hurley II-C (Severo)";
      }

      if (this.answerOne == true && this.answerFour == true) {
        text = "Hurley III (Severo)";
      }

      return !this.isIncompleted ? text : "";
    },
    isIncompleted() {
      return (
        this.answerOne == null ||
        this.answerTwo == null ||
        this.answerThree == null ||
        this.answerFour == null ||
        this.answerFive == null
      );
    },
    userId: {
      get() {
        return this.$store.state.user.userData?.id;
      },
    },
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  methods: {
    cleanForms() {
      this.answerOne = null;
      this.answerTwo = null;
      this.answerThree = null;
      this.answerFour = null;
      this.answerFive = null;
    },
    clear() {
      this.restoreData.answerOne = this.answerOne;
      this.restoreData.answerTwo = this.answerTwo;
      this.restoreData.answerThree = this.answerThree;
      this.restoreData.answerFour = this.answerFour;
      this.restoreData.answerFive = this.answerFive;

      this.initialAnswer();
      this.clearTemporalSurveys();

      this.showUndoDialog("Se limpiaron los datos de  la encuesta HURLEY.");
    },
    async save() {
      try {
        this.changeLoaderStatus(true);
        let data = {
          surveyCode: "HUR",
          result: "",
          appreciation: String(this.score),
          doctorId: String(this.userId),
          patientId: String(this.patient.document),
          detail: this.setAnswers(),
        };

        await this.$surveyService.saveSurvey(data);
        this.changeLoaderStatus(false);
        this.showToast(
          "Encuesta enviada satisfactoriamente.",
          "is-success",
          3000
        );
        this.scrollTop();
        this.initialAnswer();
        this.clearTemporalSurveys();
      } catch (error) {
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
    setAnswers() {
      let question1 = null;
      let question2 = null;
      let question3 = null;
      let question4 = null;
      let question5 = null;

      Object.keys(this.answers[questions.HUR_P1]).forEach((key) => {
        if (this.answers[questions.HUR_P1][key] == this.answerOne) {
          question1 = {
            questionCode: questions.HUR_P1,
            answerCode: key,
            value: this.answerOne,
          };
        }
      });

      Object.keys(this.answers[questions.HUR_P2]).forEach((key) => {
        if (this.answers[questions.HUR_P2][key] == this.answerTwo) {
          question2 = {
            questionCode: questions.HUR_P2,
            answerCode: key,
            value: this.answerTwo,
          };
        }
      });

      Object.keys(this.answers[questions.HUR_P3]).forEach((key) => {
        if (this.answers[questions.HUR_P3][key] == this.answerThree) {
          question3 = {
            questionCode: questions.HUR_P3,
            answerCode: key,
            value: this.answerThree,
          };
        }
      });

      Object.keys(this.answers[questions.HUR_P4]).forEach((key) => {
        if (this.answers[questions.HUR_P4][key] == this.answerFour) {
          question4 = {
            questionCode: questions.HUR_P4,
            answerCode: key,
            value: this.answerFour,
          };
        }
      });

      Object.keys(this.answers[questions.HUR_P5]).forEach((key) => {
        if (this.answers[questions.HUR_P5][key] == this.answerFive) {
          question5 = {
            questionCode: questions.HUR_P5,
            answerCode: key,
            value: this.answerFive,
          };
        }
      });
      return [question1, question2, question3, question4, question5];
    },
    validate() {
      if (!this.isIncompleted) {
        this.save();
      } else {
        this.showToast("Por favor completa la encuesta.", "is-warning", 3000);
      }
    },
    restoreForm() {
      this.answerOne = this.restoreData.answerOne;
      this.answerTwo = this.restoreData.answerTwo;
      this.answerThree = this.restoreData.answerThree;
      this.answerFour = this.restoreData.answerFour;
      this.answerFive = this.restoreData.answerFive;

      this.saveTermporalSulrvey();
    },
    saveTermporalSulrvey() {
      this.$session.set("temporalSurvey", {
        module: {
          name: "HURLEY",
          route: "Hurley",
        },
        survey: {
          answerOne: this.answerOne,
          answerTwo: this.answerTwo,
          answerThree: this.answerThree,
          answerFour: this.answerFour,
          answerFive: this.answerFive,
        },
      });
    },
    setEvaluationAnswers(data) {
      data.evaluationDetails.forEach((survey) => {
        if (survey.question.code == "HUR_P1") {
          this.answerOne = survey.value.toLowerCase() == "true" ? true : false;
        }

        if (survey.question.code == "HUR_P2") {
          this.answerTwo = survey.value.toLowerCase() == "true" ? true : false;
        }

        if (survey.question.code == "HUR_P3") {
          this.answerThree =
            survey.value.toLowerCase() == "true" ? true : false;
        }

        if (survey.question.code == "HUR_P4") {
          this.answerFour = survey.value.toLowerCase() == "true" ? true : false;
        }

        if (survey.question.code == "HUR_P5") {
          this.answerFive = survey.value.toLowerCase() == "true" ? true : false;
        }
      });
    },
    initialAnswer() {
      this.answerOne = this.answers.HUR_P1.HUR_P1_R3;
      this.answerTwo = this.answers.HUR_P2.HUR_P2_R3;
      this.answerThree = this.answers.HUR_P3.HUR_P3_R3;
      this.answerFour = this.answers.HUR_P4.HUR_P4_R3;
      this.answerFive = this.answers.HUR_P5.HUR_P5_R3;
    },
  },
};
</script>
<style scoped></style>
